import React from "react"
import Layout from "../components/layout"
import Team from "../components/Team"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"
import has from "lodash.has"
import CarouselBg from "../components/CarouselBg"
const IndexPage = ({ data }) => (
  <Layout>
    <CarouselBg>
      <div
        id="carousel-example-generic"
        className="carousel slide  "
        data-ride="carousel"
      >
        {/* Wrapper for slides */}
        <div className="carousel-inner" role="listbox">
          <div className="item active">
            {has(data, "pic_1.sharp.fluid") && (
              <Image
                className="carousel-pic "
                style={{ margin: "0 auto" }}
                fluid={data.pic_1.sharp.fluid}
              />
            )}
            {/* <img src="images/pic_1.jpg" alt className="carousel-pic" /> */}
            <div className="ins-holder">
              <h3 className="pic-head">
                Quality
                <br />
                <span>
                  Software <br /> Engineering
                </span>
              </h3>
              <p className="pic-p">For Kiwi Businesses</p>
              {/*<a href="#" class="rmore-pic">read more</a>*/}
            </div>
          </div>
        </div>
        {/* Controls */}
        {/* <a
          className="left carousel-control"
          href="#carousel-example-generic"
          role="button"
          data-slide="prev"
        ></a>
        <a
          className="right carousel-control"
          href="#carousel-example-generic"
          role="button"
          data-slide="next"
        ></a> */}
      </div>
    </CarouselBg>
    <div className="blc-2">
      <div className="container">
        <h1 style={{ color: "white" }}>Your Path To Success</h1>
        <div className="row">
          <div className="col-sm-3">
            {has(data, "Icon1.sharp.fixed") && (
              <Image
                className="icon-pic"
                style={{ display: "block" }}
                fixed={data.Icon1.sharp.fixed}
              />
            )}
            <div>
              <h3 className="blc-2-h3">Software Built Just For You</h3>
              <p>
                {" "}
                Whether it's a ERP business system or a website, we will customise
                software that highlights the unique selling points of your
                business, and conveys a crystal-clear message to your customers.
                They will know exactly who you are, what you do, and how well
                you do it.{" "}
              </p>
            </div>
          </div>
          <div className="col-sm-3">
            {has(data, "Icon2.sharp.fixed") && (
              <Image
                className="icon-pic"
                style={{ display: "block" }}
                fixed={data.Icon2.sharp.fixed}
              />
            )}{" "}
            <div>
              <h3 className="blc-2-h3">Ready To Use Out Of The Box</h3>
              <p> Our software comes installed, hosted and ready to use.</p>
            </div>
          </div>
          <div className="col-sm-3">
            {has(data, "Icon4.sharp.fixed") && (
              <Image
                className="icon-pic"
                style={{ display: "block" }}
                fixed={data.Icon4.sharp.fixed}
              />
            )}{" "}
            <div>
              <h3 className="blc-2-h3">
                Promote Your Business To Google's TOP 10
              </h3>
              <p>
                {" "}
                Our sophisticated search optimisation strategies will attract
                more new customers to your website.
              </p>
            </div>
          </div>
          <div className="col-sm-3">
            {has(data, "Icon3.sharp.fixed") && (
              <Image
                className="icon-pic"
                style={{ display: "block" }}
                fixed={data.Icon3.sharp.fixed}
              />
            )}{" "}
            <div>
              <h3 className="blc-2-h3">Increase Your Revenue</h3>
              <p>
                {" "}
                We believe in your business. We will be your partner in helping
                you achieve remarkable results.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="blc-3">
      <div className="container">
        <h1>Our Services</h1>
        {<Link to='/services/'>
        <div className="row">
        <div className="col-sm-4 text-center">
            {has(data, "OIcon3.sharp.fixed") && (
              <Image className="orange-icon" fixed={data.OIcon3.sharp.fixed} />
            )}{" "}
            <div>
              {/* <p>Reliable and robust</p> */}
              <h3>ERP Systems</h3>
            </div>
          </div>
          <div className="col-sm-4 text-center">
            {has(data, "OIcon2.sharp.fixed") && (
              <Image className="orange-icon" fixed={data.OIcon2.sharp.fixed} />
            )}{" "}
            <div>
              {/* <p>Easily manageable</p> */}
              <h3>E-commerce solutions</h3>
            </div>
          </div>
          <div className="col-sm-4 text-center">
            {has(data, "OIcon1.sharp.fixed") && (
              <Image className="orange-icon" fixed={data.OIcon1.sharp.fixed} />
            )}
            <div>
              {/* <p>Performance Optimisation</p> */}
              <h3>IT Performance Optimisation</h3>
            </div>
          </div>
          
          
          {/* <div className="col-sm-3 text-center">
            {has(data, "OIcon4.sharp.fixed") && (
              <Image className="orange-icon" fixed={data.OIcon4.sharp.fixed} />
            )}{" "}
            <div>
              <p>Elegant and intuitive</p>
              <h3>Mobile Apps</h3>
            </div>
          </div> */}
          {/* <div class="col-sm-3 text-center"><img src="images/o_icon_05_Draft.png" alt="" class="orange-icon"><div><p>Powerful and effective</p><h3>SEO</h3></div></div> */}
        </div>
        </Link>}
      </div>
    </div>
    <div className="blc-4">
      <div className="container">
        <Team />
      </div>
    </div>
  </Layout>
)

export default IndexPage
export const query = graphql`
  {
    pic_1: file(publicURL: { regex: "/pic_1_cropped.jpg/" }) {
      sharp: childImageSharp {
        fluid(maxWidth: 600, srcSetBreakpoints: [180, 320, 460]) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Icon1: file(publicURL: { regex: "/i_01.png/" }) {
      sharp: childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    Icon2: file(publicURL: { regex: "/i_02.png/" }) {
      sharp: childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    Icon3: file(publicURL: { regex: "/i_03.png/" }) {
      sharp: childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    Icon4: file(publicURL: { regex: "/i_04.png/" }) {
      sharp: childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    OIcon1: file(publicURL: { regex: "/o_icon_01.png/" }) {
      sharp: childImageSharp {
        fixed(height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    OIcon2: file(publicURL: { regex: "/o_icon_02.png/" }) {
      sharp: childImageSharp {
        fixed(height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    OIcon3: file(publicURL: { regex: "/o_icon_03.png/" }) {
      sharp: childImageSharp {
        fixed(height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    OIcon4: file(publicURL: { regex: "/o_icon_04.png/" }) {
      sharp: childImageSharp {
        fixed(height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
